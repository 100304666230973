.project-container {
	margin-left: 300px;
}

.column {
	float: left;
	width: 33.33%;
	padding: 5px;
}

img {
	width: 30%;
	height: auto;
	padding: 10px;
}

.info {
	display: inline-block;
}

.project-title {
	font-weight: 500;
	font-family: "Poppins", Arial, sans-serif;
	font-size: 20px;
}

.info a {
	text-decoration: none;
	color: rgb(176, 131, 148);
	font-family: "Poppins", Arial, sans-serif;
	font-size: 15px;
}

.info a:hover {
	color: black;
}

/* @media (max-width: 450px) {
	.project-container {
		margin-left: 100px !important;
	}
	img {
		width: 40%;
	}
	.project-title {
		padding-top: 10px;
		font-size: 15px;
	}
} */

@media (max-width: 600px) {
	.project-container {
		margin-left: 100px;
	}
	img {
		width: 40%;
	}
	.project-title {
		padding-top: 10px;
		font-size: 15px;
	}
}

@media (min-width: 600px) {
	.project-container {
		margin-left: 100px;
	}
	img {
		width: 40%;
	}
	.project-title {
		padding-top: 10px;
		font-size: 15px;
	}
}

@media (min-width: 768px) {
	.project-container {
		margin-left: 350px;
	}
}

@media (min-width: 992px) {
	.project-container {
		margin-left: 330px;
	}
}

@media (min-width: 1200px) {
	.project-container {
		margin-left: 330px;
	}
}
