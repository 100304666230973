.scrollbar {
	margin-left: 30px;
	float: left;
	height: auto;
	overflow-y: scroll;
	margin-bottom: 30px;
	/* background-color: rgba(255, 255, 255, 0.5); */
}

.scrollbar-primary::-webkit-scrollbar {
	width: 5px;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: rgb(176, 131, 148);
}

.hero-wrap {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 0;
}

.subheading {
	font-size: 24px;
	color: lighten(rgb(0, 0, 0.5));
	font-weight: 300;
}

h1 {
	letter-spacing: 1px;
	font-weight: 900;
}

.force-overflow {
	min-height: 450px;
}

.resume-button {
	background-color: rgb(176, 131, 148) !important;
	border: none;
	border-radius: 20px;
}
.resume-button:hover {
	color: black !important;
}

/* .skills-title {
	font-weight: 500;
	color: rgb(176, 131, 148);
	font-size: 34px;
} */

.navimage {
	width: 50%;
	height: 220px;
	margin-left: 25%;
	margin-bottom: 20px;
	/* border-radius: 50%; */
	position: relative;
	overflow: hidden;
}
.navimage img {
	min-width: 100%;
	min-height: 100%;
	border-radius: 30%;
}
/* .skills {
	font-weight: 500;
} */

@media (max-width: 600px) {
	.about-container {
		margin-left: -140px;
	}
	.navimage {
		width: 120px;
		height: 170px;
		margin-left: 40px;
	}
	h1 {
		font-size: 20px;
		font-weight: 700;
	}
	.scrollbar {
		margin-left: 20px;
		height: 350px;
	}
	.paragraph {
		font-size: 12px;
		font-weight: 300;
	}
	.subheading {
		font-weight: 300;
		font-size: 30px;
		color: rgb(176, 131, 148);
	}
}

@media (min-width: 600px) {
	.about-container {
		margin-left: -120px;
	}
	.navimage {
		width: 140px;
		height: 170px;
		margin-left: 80px;
	}
	h1 {
		font-size: 20px;
		font-weight: 700;
	}
	.scrollbar {
		margin-left: 20px;
		height: 350px;
	}
	.paragraph {
		font-size: 12px;
		font-weight: 300;
	}
}

@media (min-width: 768px) {
	.about-container {
		margin-left: 50px;
	}
	.subheading {
		font-size: 30px;
		color: lighten(rgb(0, 0, 0.5));
		font-weight: 300;
	}

	h1 {
		letter-spacing: 1px;
		font-weight: 900;
		font-size: 40px;
	}
	.paragraph {
		font-size: 15px;
	}
	.scrollbar {
		height: 500px;
	}
}

@media (min-width: 992px) {
	.about-container {
		margin-left: 140px;
	}
	.subheading {
		font-size: 24px;
		color: lighten(rgb(0, 0, 0.5));
		font-weight: 300;
	}

	h1 {
		letter-spacing: 1px;
		font-weight: 900;
		font-size: 34px;
	}
	.paragraph {
		font-size: 15px;
	}
	.navimage {
		margin-left: 165px;
	}
}

@media (min-width: 1200px) {
	.navimage {
		width: 200px;
		height: 250px;
		border-radius: 0%;
		margin-left: 190px;
	}
}
