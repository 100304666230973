.social {
	margin-right: 40px;
}

.main-nav {
	margin-right: 20px;
	font-weight: 400;
}

.page-footer {
	font-size: small;
	/* margin-right: 500px; */
	bottom: 0px;
	position: fixed;
	text-decoration: none;
	background-color: rgb(176, 131, 148);
}

.nav-link :hover {
	background-color: rgb(176, 131, 148);
}

.nav-name {
	font-family: "Poppins", Arial, sans-serif;
	width: 100%;
	font-weight: 900;
	font-size: 34px;
}

ul {
	font-family: "Poppins", Arial, sans-serif;
}

#sidebar.active {
	margin-left: -17rem;
}

#content.active {
	width: 100%;
	margin: 0;
}

.facebook,
.linkedin,
.instagram,
.github {
	color: black;
}

.instagram:hover,
.github:hover,
.linkedin:hover {
	color: rgb(176, 131, 148);
}

ul {
	list-style-type: none;
}

.period {
	font-size: 60px;
	line-height: 70px;
	color: rgb(176, 131, 148);
}

.vertical-nav {
	min-width: 17rem;
	width: 17rem;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.4s;
}

.page-content {
	width: calc(100% - 17rem);
	margin-left: 17rem;
	transition: all 0.4s;
}

#sidebar.active {
	margin-left: -17rem;
}

#content.active {
	width: 100%;
	margin: 0;
}

.separator {
	margin: 3rem 0;
	border-bottom: 1px dashed #fff;
}

.text-uppercase {
	letter-spacing: 0.1em;
}

.text-gray {
	color: #aaa;
}

.article-margin {
	margin-left: 200px;
}

@media (max-width: 600px) {
	.vertical-nav {
		width: 5rem;
		min-width: 5rem;
	}
	.nav-name {
		font-size: 10px;
	}
	.title {
		font-size: 8px;
	}
	.hello {
		font-size: 8px;
		margin-left: -10px;
	}
	.main-nav {
		font-size: 10px;
	}

	.page-footer {
		font-size: 6px;
		bottom: 0px;
		position: fixed;
		text-decoration: none;
		margin-left: -45px;
	}

	.nav-link {
		margin-left: -10px;
	}

	.social {
		margin-left: -20px;
	}
}

@media (min-width: 600px) {
	.vertical-nav {
		width: 5rem;
		min-width: 5rem;
	}
	.nav-name {
		font-size: 10px;
	}
	.title {
		font-size: 8px;
	}
	.hello {
		font-size: 8px;
		margin-left: -10px;
	}
	.main-nav {
		font-size: 10px;
	}

	.page-footer {
		font-size: 6px;
		bottom: 0px;
		position: fixed;
		text-decoration: none;
		margin-left: -45px;
	}

	.nav-link {
		margin-left: -10px;
	}

	.social {
		margin-left: -20px;
	}
}

@media (min-width: 768px) {
	.vertical-nav {
		min-width: 17rem;
		width: 17rem;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
		transition: all 0.4s;
	}
	.nav-name {
		font-weight: 900;
		font-size: 34px;
	}
	.nav-link {
		margin: 10px;
	}
	.title {
		font-size: 15px;
	}
	.main-nav {
		font-size: 15px;
	}
}

@media (min-width: 992px) {
	.vertical-nav {
		min-width: 17rem;
		width: 17rem;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
		transition: all 0.4s;
	}
	.main-nav {
		font-size: 20px;
	}
	.social {
		font-size: 20px;
	}
	.hello {
		font-size: 15px;
	}
}

@media (min-width: 1200px) {
	.vertical-nav {
		min-width: 17rem;
		width: 17rem;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
		transition: all 0.4s;
	}
	.main-nav {
		font-size: 15px;
	}
	.main-nav li {
		font-size: 20px;
		margin-top: -10px;
	}
	.hello {
		font-size: 20px;
	}
	.social {
		font-size: 15px;
	}
	.nav-name {
		font-size: 60px;
	}
	.period {
		font-size: 100px;
	}
	.title {
		font-size: 20px;
	}
}
