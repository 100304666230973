.contact-container {
	margin-left: 100px;
	color: #000;
	font-family: "Roboto", sans-serif;
}
.contact-form {
	padding: 50px;
	margin: 30px auto;
}
.contact-form h1 {
	margin-top: -30px;
	font-size: 42px;
	font-family: "Poppins", Arial, sans-serif;
	padding-bottom: 5px;
	text-align: center;
	letter-spacing: -1px;
}

.contact-period {
	font-size: 70px;
	color: rgb(176, 131, 148);
}
.contact-form .form-group {
	margin-bottom: 20px;
}

.contact-form .form-control {
	color: rgb(176, 131, 148);
}
.contact-form .form-control:focus {
	color: black;
	/* outline: none !important; */
}

.contact-form .submit-button {
	color: white;
	background: rgb(176, 131, 148) !important;
	border-radius: 20px;
	box-shadow: #000;
}
.contact-form .submit-button:hover {
	color: black;
}

.form-group textarea {
	border-radius: 20px;
	border-color: rgb(176, 131, 148);
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
	resize: none;
}

input:focus {
	outline: rgb(176, 131, 148) !important;
	box-shadow: none !important;
	border: rgb(176, 131, 148) !important;
}

textarea:focus {
	outline: rgb(176, 131, 148) !important;
	box-shadow: none !important;
	border: rgb(176, 131, 148) !important;
}

.form-group input {
	border-radius: 10px;
	border-color: rgb(176, 131, 148);
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.email {
	text-decoration: none;
	color: rgb(176, 131, 148);
	font-size: 20px;
	font-family: "Poppins", Arial, sans-serif;
}

.email:hover {
	text-emphasis: none;
	color: black;
}

.icon {
	padding-left: 4px;
	padding-right: 4px;
	color: black;
	font-size: larger;
}

.email-div {
	padding-top: 4px;
	margin-left: 70px;
	width: auto;
}

.email-info {
	background-color: rgba(255, 255, 255, 0.502);
}

.email-words {
	font-size: 25px;
}

.social-contact {
	font-size: xx-large;
	margin-left: 270px;
}

.c-instagram,
.c-github,
.c-linkedin {
	padding-left: 11px;
	color: black;
}

.c-instagram:hover,
.c-github:hover,
.c-linkedin:hover {
	color: rgb(176, 131, 148);
}

@media (max-width: 600px) {
	.contact-container {
		margin-left: 50px;
	}
	.email-info {
		margin-left: -70px;
		font-size: 20px;
	}
	.email {
		color: rgb(176, 131, 148);
	}
	.email:hover {
		color: black;
	}

	.icon:hover {
		color: rgb(176, 131, 148);
	}
	.social-contact {
		margin-left: 100px;
		align-items: center;
	}
	.contact-form h1 {
		margin-top: -80px;
		letter-spacing: -2px;
		font-size: 40px;
	}
}

@media (min-width: 600px) {
	.contact-container {
		margin-left: 50px;
	}
	.social-contact {
		margin-left: 100px;
		align-items: center;
	}
	.contact-form h1 {
		margin-top: -90px;
	}
	.email-info {
		margin-left: -70px;
		font-size: 20px;
	}
	.email {
		color: rgb(176, 131, 148);
	}
	.email:hover {
		color: black;
	}
	.icon:hover {
		color: black;
	}
}

@media (min-width: 768px) {
	.contact-container {
		margin-left: 200px;
	}
	.contact-form {
		padding: 50px;
		margin: 30px auto;
	}
	.contact-form h1 {
		margin-top: -80px;
		font-size: 42px;
		font-family: "Poppins", Arial, sans-serif;
		padding-bottom: 5px;
		text-align: center;
		letter-spacing: -1px;
	}
	.email-words {
		font-size: 35px;
		font-weight: 400;
		background-color: transparent;
	}
	.email {
		font-size: 30px;
		background-color: transparent;
		color: rgb(176, 131, 148);
		font-weight: 500;
	}

	.icon {
		color: black;
	}
	.icon:hover {
		color: rgb(176, 131, 148);
	}
	.email:hover {
		color: black;
	}
	.social-contact {
		font-size: 40px;
	}
}

@media (min-width: 992px) {
	.contact-container {
		margin-left: 140px;
	}
	.email {
		font-weight: normal;
		font-size: 30px;
	}
	.email-words {
		font-weight: normal;
		font-size: 30px;
	}
	.social-contact {
		margin-left: 200px;
	}
	.contact-form h1 {
		font-size: 70px;
		margin-top: -90px;
	}
	.contact-period {
		font-size: 140px;
	}
}

@media (min-width: 1200px) {
	.email-info {
		margin-left: auto;
		width: auto;
	}
	.email-words {
		font-size: 30px;
	}
	.email {
		font-size: 30px;
	}
}
